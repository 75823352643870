@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.footer_container {
	display: flex;
	flex-direction: row;
	position: fixed;
	width: 100%;
	bottom: 0;
	font-size: 0.8em;
	font-weight: 700;
	background-color: $gruvbox_dark1;

	@media screen and (max-width: $small) {
		display: none;
	}
}

.branch {
	@include right_chevron;
	@include stacked_footer_item;
	display: flex;
	position: relative;
	left: -2em;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: $gruvbox_light1;
	padding: inherit;
	background-color: $gruvbox_dark3;
	width: 175px;
}

.file {
	@include footer_item;
	color: $gruvbox_gray;
	min-width: calc(50vw - 325px);
	justify-content: flex-start;
}

.plugins {
	@include footer_item;

	color: $gruvbox_gray;
	min-width: calc(50vw - 155px);
	justify-content: flex-end;
	svg {
		font-size: 0.5rem;
	}
}

.session {
	@include left_chevron;
	@include footer_item;
	@include stacked_footer_item;

	background: $gruvbox_gray;
	background-color: $gruvbox-dark3;
	color: $gruvbox_light1;
	width: 125px;
	position: relative;
	right: -2em;
}

.time {
	@include footer_item;
	@include stacked_footer_item;
	background-color: $gruvbox_gray;
	width: 160px;
	svg {
		font-size: 0.5rem;
	}
}

.packages {
	color: $gruvbox_neutral_orange;
}
