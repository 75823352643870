@import '../../../styles/variables.scss';

.manage_post_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100vh;
	color: $gruvbox_light1;
}

.title_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 75vw;
}

.btn_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 250px;
	padding: 1em 0;
}

.btn {
	border: 1px solid $gruvbox_light1;
	border-radius: 0.5em;
	cursor: pointer;
	padding: 0.5em 1em;
	opacity: 0.7;
	&:hover {
		border-color: $gruvbox_neutral_orange;
		color: $gruvbox_neutral_orange;
		opacity: 1;
		transition: border, color, opacity;
		transition-duration: 0.25s;
		transition-timing-function: ease-in-out;
	}
}

.edit_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.input_label {
	padding: 0.5em 0;
	width: 75px;
	text-align: left;
}

.banner_src_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 75vw;
	padding: 1em 0;
}

.title_input_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 75vw;
	padding: 1em 0;
}

.summary_input_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 75vw;
	padding: 1em 0;
	min-height: 35px;
}

.input {
	background-color: $gruvbox_dark1;
	color: $gruvbox_light1;
	border: none;
	padding: 0.5em;
	width: 75vw;
	border: 1px solid $gruvbox_dark0;
	border-radius: 5px;
	height: calc(35px - 1em);
	line-height: calc(35px - 1em);
}

input:focus {
	outline: none;
	background-color: $gruvbox_dark0;
	caret-color: $gruvbox_light1;
	border-color: $gruvbox_dark1;
}

textarea:focus {
	outline: none;
	background-color: $gruvbox_dark0;
	caret-color: $gruvbox_light1;
	border-color: $gruvbox_dark1;
}

.markdown_editor {
	min-height: 750px;
	width: 75vw;
}
