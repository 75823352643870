/********** 
 * Colors *
 **********/
$gruvbox_dark0_hard: #1d2021;
$gruvbox_dark0: #282828;
$gruvbox_dark_soft: #32302f;
$gruvbox_dark1: #3c3836;
$gruvbox_dark2: #504945;
$gruvbox_dark3: #665c54;
$gruvbox_dark4: #7c6f64;
$gruvbox_gray: #928374;
$gruvbox_light1: #ecdbb2;
$gruvbox_neutral_red: #cc241d;
$gruvbox_neutral_green: #98971a;
$gruvbox_neutral_yellow: #d79921;
$gruvbox_neutral_blue: #458588;
$gruvbox_neutral_purple: #b16286;
$gruvbox_neutral_aqua: #689d6a;
$gruvbox_neutral_orange: #d65d0e;

$terminal_background: #32302f;
$terminal_text: #c1c1c1;
$terminal_border: #272727;

$black: #000000;

/**********
* Display *
**********/
$small: 800px;
$medium: 1200px;
$large: 1500px;
