@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.list_container {
	@include border_title;
	height: 25vh;
	width: 75vw;
}

.list_wrapper {
	height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: -1em;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 1rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: $gruvbox_neutral_orange;
	}
	&.selected {
		color: $gruvbox_neutral_orange;
	}
}
