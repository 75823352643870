@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.error_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	height: 75vh;
	max-height: 750px;
	color: $gruvbox_light1;
}

.ascii_banner {
	@include ascii_banner;
	padding-right: 2em;
}

.ascii_banner_container {
	@include ascii_banner_container;
}

.text_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	@media screen and (min-width: $small) {
		padding: 5rem 2rem;
	}
}

.back_btn {
	cursor: pointer;
	opacity: 0.7;
	&:hover {
		color: $gruvbox_neutral_orange;
		opacity: 1;
		transition: opacity 0.25s ease-in-out;
	}
}
