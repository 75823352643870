@import '../../../styles/variables.scss';

.post_preview_container {
	display: flex;
	justify-content: space-between;
	border: 1px solid $gruvbox_dark0;
	border-radius: 2em;
	&:hover {
		cursor: pointer;
		border: 1px solid $gruvbox_neutral_orange;
		transition: border 0.5s ease-in-out;
	}
}

.post_preview {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	margin: 0 2rem;
	padding: 1em;
}

.post_preview_summary {
	text-align: left;
}

.title_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.contributor_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 0 2rem;
	padding-top: 2rem;
	min-width: 125px;
}

.contributor_img {
	height: 6rem;
	width: 6rem;
	object-fit: cover;
	border-radius: 50%;
}
