@import '../../styles/variables.scss';

.about_container {
	display: flex;
	flex-direction: row;
	height: 90vh;
	width: 75vw;
	text-align: left;
	flex-wrap: wrap;
}

.profile_sidebar {
	padding-top: 1.5em;
	min-width: 20vw;
	text-align: left;
}

.profile_pic {
	height: 12em;
	width: 12em;
	object-fit: cover;
	border-radius: 50%;
}

.email {
	padding: 1em 0;
	&:hover {
		color: $gruvbox_neutral_orange;
		cursor: pointer;
	}
}

.bio {
	width: 55vw;
}

.link {
	display: inline-block;
	margin: 0;
	color: $gruvbox_neutral_orange;
	cursor: pointer;
}
