@import 'variables';

@mixin border_title {
	border: 1px solid $gruvbox_light1;
	background-color: $gruvbox_dark0;
}

@mixin terminal_button {
	display: flex;
	height: 12px;
	width: 12px;
	border-radius: 10px;
	background-color: $gruvbox_gray;
	margin: auto 5px;
}

@mixin footer_item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 0.25rem;
}

@mixin stacked_footer_item {
	max-width: 125px;
}

@mixin right_arrow {
	clip-path: polygon(0% 0%, 75% 0%, 90% 50%, 75% 100%, 0% 100%);
}

@mixin right_chevron {
	clip-path: polygon(75% 0%, 93% 50%, 75% 100%, 0% 100%, 7% 50%, 0% 0%);
}

@mixin left_arrow {
	clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 10% 50%);
}

@mixin left_chevron {
	clip-path: polygon(100% 0%, 93% 50%, 100% 100%, 25% 100%, 7% 50%, 25% 0%);
}

@mixin title_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

@mixin ascii_banner_container {
	display: flex;
	flex-wrap: wrap;
	max-width: 100vw;
	justify-content: center;
	min-height: 15em;
	padding: auto 0.5rem;

	@media screen and (max-width: $small) {
		padding-bottom: 2em;
	}
}

@mixin ascii_banner {
	font-size: 1.5em;
	color: $gruvbox_light1;
	padding: 0.5em 0;
	max-width: 25rem;
	max-height: 6em;

	@media screen and (max-width: $small) {
		font-size: 1.25em;
	}
}
