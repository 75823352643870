@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.modal_background {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.centered {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: $gruvbox_dark0;
	color: white;
	z-index: 10;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modal_header {
	height: 20px;
	background: $gruvbox_dark1;
	overflow: hidden;
}

.heading {
	margin: 0;
	padding: 2px 5px;
	color: $gruvbox_gray;
	font-weight: 500;
	text-align: right;
}

.close {
	&:hover {
		cursor: pointer;
		color: $gruvbox_neutral_orange;
	}
}

.modal_scroll_container {
	height: calc(100vh - 20px);
	overflow-y: scroll;
}

.modal_content {
	padding: 10px;
	font-size: 14px;
	color: $gruvbox_light1;
	display: flex;
	flex-direction: column;
	max-width: 75vw;
	margin: 0 auto;
	justify-content: space-between;
	min-height: 90vh;
	max-height: 100%;
}
